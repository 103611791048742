<template>
  <v-card rounded="lg" flat class="transparent fill-height">
    <div class="d-flex flex-column justify-space-between fill-height" :style="m_iRowStyle">
      <div>
        <v-row no-gutters :style="m_iRowStyle">
          <v-col cols="12">
            <base-img :src="imgSrc" :max-height="small ? 172 : 300" />
          </v-col>

          <v-col v-if="strLabel" cols="12">
            <v-chip
              :color="strLabelColor"
              :style="{ backgroundColor: `${hexLabelColor} !important` }"
              :class="`kda-ts-${small ? '12' : '16'}pt nunito wt-semibold white--text`"
              :small="small"
            >
              {{ strLabel }}
            </v-chip>
          </v-col>

          <v-col cols="12">
            <h1
              :class="`${
                small ? 'kda-ts-16pt line-clamp-2 cursor-pointer' : 'kda-ts-20pt line-clamp-3'
              } nunito wt-extrabold`"
              style="line-height: 120%"
              @click="$emit('info')"
            >
              {{ m_objTitle.strText }}
            </h1>
          </v-col>

          <v-col cols="12">
            <p
              :class="`${
                small ? 'kda-ts-12pt line-clamp-3' : 'kda-ts-14pt line-clamp-4'
              } nunito ln-170`"
              v-html="m_strDesc"
            ></p>
          </v-col>
        </v-row>
      </div>

      <div>
        <v-btn
          v-if="!small"
          color="main-r-450"
          block
          class="kda-ts-16pt nunito white--text text-capitalize"
          @click="$emit('info')"
        >
          {{ m_strButtonLabel }}
          <v-icon right> mdi-chevron-right </v-icon>
        </v-btn>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'KDABlogCard',

  props: {
    strTitle: {
      type: String,
      default: ''
    },
    strLabel: {
      type: String,
      default: ''
    },
    hexLabelColor: {
      type: String,
      default: ''
    },
    strLabelColor: {
      type: String,
      default: ''
    },
    strDescription: {
      type: String,
      default: ''
    },
    imgSrc: {
      type: String,
      default: ''
    },
    small: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    m_objTitle() {
      return {
        strText: this.strTitle,
        strComponent: 'p'
      };
    },
    m_strDesc() {
      return this.strDescription.replaceAll('<br/>', ' ');
    },
    m_strButtonLabel() {
      return this.$vuetify.lang.t('$vuetify.kda.blog.read');
    },
    m_iRowStyle() {
      return { gap: this.g_bLowerBr ? '8px' : '16px' };
    }
  }
};
</script>

<style scoped lang="scss">
.cursor-pointer {
  cursor: pointer;
}
</style>
